import { clients } from '../lib/clients'
import { useCallback, useEffect } from 'react'
import { atom, useAtom } from 'jotai'
import { useAuth0 } from '@auth0/auth0-react'
import { getAPIAccessToken } from '../lib/auth0'
import { flatten } from '../lib/util'

type Organizations = Record<
  string,
  Awaited<ReturnType<(typeof clients)['/api/@me/organizations']['GET']['client']>>['body']['organizations'][number]
>

type OrganizationsState = {
  initializedFlag: boolean
  ids: string[]
  organizations: Organizations
}

const organizationsState = atom<OrganizationsState>({
  initializedFlag: false,
  ids: [],
  organizations: {}
})

export const useOrganizations = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const [organizations, setorganizations] = useAtom(organizationsState)

  const getOrganizations = useCallback(async () => {
    const accessToken = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
    const res = await clients['/api/@me/organizations'].GET.client({
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    const { ids, record } = flatten(res.body.organizations)
    setorganizations({
      initializedFlag: true,
      ids: ids,
      organizations: record
    })

    return res
  }, [getAccessTokenSilently, getAccessTokenWithPopup, setorganizations])

  useEffect(() => {
    if (!organizations.initializedFlag) {
      getOrganizations()
    }
  }, [getOrganizations, organizations.initializedFlag])

  return {
    organizations,
    getOrganizations
  }
}
