import type { Auth0ContextInterface } from '@auth0/auth0-react'

type GetAccessTokenSilentlyType = Auth0ContextInterface['getAccessTokenSilently']
type GetAccessTokenWithPopupType = Auth0ContextInterface['getAccessTokenWithPopup']

export const getAPIAccessToken = async (
  getAccessTokenSilently: GetAccessTokenSilentlyType,
  getAccessTokenWithPopup: GetAccessTokenWithPopupType,
  options: {
    authorizationParams: {
      audience: string
    }
  }
) => {
  try {
    return await getAccessTokenSilently({ ...options })
  } catch (e) {
    if (Object.prototype.hasOwnProperty.call(e, 'error') && (e as { error: unknown }).error === 'consent_required') {
      return getAccessTokenWithPopup({ ...options })
    }
    throw e
  }
}
