/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { defineApis, define } from '@tiny-type-api/universal'
import type { AnalyticsEvevntDeviceAge } from '../types/eventAnalytics'

type AuthHeader = { Authorization: `Bearer ${string}` }

export const { apis } = defineApis({
  '/api/@me': {
    GET: {
      request: {
        headers: define<AuthHeader>()
      },
      response: {
        200: {
          body: define<{ email: string; roles: string[] }>()
        },
        404: {
          body: define<''>()
        }
      }
    }
  },
  '/api/@me/profile': {
    POST: {
      request: {
        headers: define<AuthHeader>()
      },
      response: {
        200: {
          body: define<void>()
        }
      }
    }
  },
  '/api/@me/actcastDeviceGroups': {
    GET: {
      request: {
        headers: define<AuthHeader>()
      },
      response: {
        200: {
          body: define<{
            actcastDeviceGroups: {
              _id: string
              name: string
              organizationId: string
              actcastDevices: string[]
            }[]
          }>()
        }
      }
    }
  },
  '/api/@me/organizations': {
    GET: {
      request: {
        headers: define<AuthHeader>()
      },
      response: {
        200: {
          body: define<{
            organizations: {
              _id: string
              name: string
              owners: {
                _id: string
                email: string
              }[]
            }[]
          }>()
        }
      }
    }
  },
  '/api/actcastDeviceGroups/:groupId/devices': {
    GET: {
      request: {
        headers: define<AuthHeader>()
      },
      response: {
        200: {
          body: define<{
            actcastDevices: {
              _id: string
              actcastId: string
              hostname: string
              name: string
              mac_addr: string
              tags: string[]
              foundness: string
              status: {
                uptime: number
                memory: number
                disk: number
                memory_usage: number
                disk_usage: number
                throttled: number
                cpu_temperature: number
              } | null
              act: {
                id: number
                name: string
                artifact_id: string
                created_at: string
              } | null
            }[]
          }>()
        }
      }
    }
  },
  '/api/analytics/event/device/:deviceId': {
    GET: {
      request: {
        headers: define<AuthHeader>()
      },
      response: {
        200: {
          body: define<
            | {
                type: 9249
                allCount: {
                  count: number
                }
                gender: { _id: string; count: number }[]
                age: {
                  average: number
                  range: {
                    '0_9': AnalyticsEvevntDeviceAge
                    '10_19': AnalyticsEvevntDeviceAge
                    '20_29': AnalyticsEvevntDeviceAge
                    '30_39': AnalyticsEvevntDeviceAge
                    '40_49': AnalyticsEvevntDeviceAge
                    '50_59': AnalyticsEvevntDeviceAge
                    '60_69': AnalyticsEvevntDeviceAge
                    '70_79': AnalyticsEvevntDeviceAge
                    '80_': AnalyticsEvevntDeviceAge
                  }
                }
              }
            | {
                type: 'no_event_model'
              }
          >()
        }
      }
    }
  },
  '/api/photo/:deviceId/latest': {
    GET: {
      request: {
        headers: define<AuthHeader>()
      },
      response: {
        200: {
          body: define<{
            url: string
            taken_at: string // TODO: yyyy-mm-ddThh:MM:ssZ 形式
          } | null>()
        }
      }
    }
  },
  '/api/photo/:deviceId/request': {
    GET: {
      request: {
        headers: define<AuthHeader>()
      },
      response: {
        200: {
          body: define<void>()
        }
      }
    }
  },
  '/api/photo/:deviceId/list': {
    GET: {
      request: {
        headers: define<AuthHeader>()
      },
      response: {
        200: {
          body: define<{
            files: {
              url: string
              taken_at: string // TODO: yyyy-mm-ddThh:MM:ssZ 形式
            }[]
          }>()
        }
      }
    }
  },
  '/api/deliveryDevice/log': {
    POST: {
      request: {
        headers: define<{
          Authorization: string
        }>(),
        body: define<{
          photo: string
          latitude: number
          longitude: number
          altitude: number
          timestamp: string
        }>()
      },
      response: {
        200: {
          body: define<void>()
        }
      }
    }
  },
  '/api/notifications': {
    GET: {
      request: {
        headers: define<{
          Authorization: string
        }>()
      },
      response: {
        200: {
          body: define<{
            notifications: ({
              _id: string
              organizationId: string
              message: string
              createdAt: string
            } & {
              type: 'message_only'
            })[]
          }>()
        }
      }
    }
  },
  '/api/system/photo/genderAgeAnalysis': {
    POST: {
      request: {
        body: define<{
          device_id: string
          timestamp: number
          image: string
        }>()
      },
      response: {
        200: {
          body: define<void>()
        }
      }
    }
  },
  '/api/system/notifications': {
    POST: {
      request: {
        headers: define<AuthHeader>(),
        body: define<{
          message: string
        }>()
      },
      response: {
        200: {
          body: define<void>()
        }
      }
    }
  },
  '/api/system/actcast/webhook': {
    POST: {
      request: {
        body: define<unknown>()
      },
      response: {
        200: {
          body: define<void>()
        }
      }
    }
  }
})

export type API = typeof apis
